import React, { useContext, useEffect, useState } from 'react';
import { FC } from 'react';
import {
  Button,
  Divider,
  Drawer,
  Hidden,
  List,
  ListItem,
  ListItemText,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Box,
  makeStyles,
  CardActions,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import authAxios from 'src/utils/authAxios';
import UserContext from 'src/contexts/UserContext';

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: '100%',
    margin: '16px',
  },
  media: {
    width: '100%',
    height: 'auto',
    minHeight: '615px',
    [theme.breakpoints.down('xs')]: {
      minHeight: '215px',
    },
  },
}));

const OnboardingGuide: FC = () => {
  const classes = useStyles();
  const userContext = useContext(UserContext);
  const companyId = userContext.companyID;
  const businessCurrency = userContext.baseCurrency;

  const [openDrawer, setOpenDrawer] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogVideo, setDialogVideo] = useState('');
  const [dialogText, setDialogText] = useState('');
  const [doneSteps, setDoneSteps] = useState({
    linkedBankAccounts: false,
    importHistoricalData: false,
    installApp: false,
    uploadReceipt: false,
    sendInvoiceWithStripe: false,
    reviewTransactions: false,
    viewFinancialReports: false,
    salesTaxFiling: false,
  });

  //get initial onboarding steps state
  useEffect(() => {
    const fetchDoneSteps = async () => {
      try {
        setDoneSteps(prev => ({ ...prev, ...userContext.onboardingSteps }));
      } catch (err) {
        console.error(
          err.response.data.message
            ? err.response.data.message
            : "Couldn't fetch onboarding steps"
        );
      }
    };

    fetchDoneSteps();
  }, [companyId]);

  const storeViewedOnboardingStep = async onboardingStep => {
    try {
      const axiosReq = await authAxios();
      const res = await axiosReq.post(
        `companies/store-viewed-onboarding-step/${companyId}`,
        onboardingStep
      );
      return res.data;
    } catch (err) {
      throw err.response.data.message
        ? err.response.data.message
        : "Couldn't add rule";
    }
  };

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const handleClickOpenDialog = (
    onboardingStep,
    onboardingVideo,
    onboardingText
  ) => {
    storeViewedOnboardingStep({ onboardingStep });
    setOpenDialog(true);
    setDialogVideo(onboardingVideo);
    setDialogText(onboardingText);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <>
      {!Object.values(doneSteps).some(step => step) ? (
        <Hidden smDown>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleDrawerOpen}
            className="onboarding-guide-button"
            style={{
              animation: 'flash 3s infinite',
            }}
          >
            Onboarding Guide
          </Button>
          <style>
            {`
            @keyframes flash {
            0%, 100% { border-color:rgb(18, 0, 110); color: inherit; background-color: inherit; }
            50% { border-color:rgb(18, 0, 110); color: inherit; background-color: #ffe0b2; }
            }
            .onboarding-guide-button {
            animation: flash 3s infinite;
            }
            `}
          </style>
        </Hidden>
      ) : (
        <Hidden smDown>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleDrawerOpen}
          >
            Onboarding Guide
          </Button>
        </Hidden>
      )}
      <Hidden mdUp>
        <Button variant="outlined" color="secondary" onClick={handleDrawerOpen}>
          Onboarding
        </Button>
      </Hidden>
      <Drawer
        variant="temporary"
        anchor="right"
        open={openDrawer}
        onClose={handleDrawerClose}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginLeft: 10,
            marginTop: 10,
          }}
        >
          <Typography
            color="secondary"
            variant="h4"
            style={{ marginBottom: 12, marginTop: 12 }}
          >
            Onboarding Guide
          </Typography>
          <Button
            onClick={handleDrawerClose}
            style={{ minWidth: 'auto', padding: 0 }}
          >
            <CloseIcon style={{ marginRight: 8 }} />
          </Button>
        </div>

        <List>
          <Divider style={{ height: '2px' }} />
          <ListItem
            button
            onClick={() => {
              handleClickOpenDialog(
                'linkedBankAccounts',
                'https://www.youtube.com/embed/172lZN_DdCU?hd=1',
                'https://www.reinvestwealth.com/post/linking-business-bank-accounts-reinvestwealth-accounting-software'
              );
              setDoneSteps(prev => ({ ...prev, linkedBankAccounts: true }));
            }}
          >
            <ListItemText primary={'1. Link Bank Accounts'} />
            {doneSteps.linkedBankAccounts && (
              <DoneIcon style={{ marginLeft: 10 }} />
            )}
          </ListItem>
          <Divider />
          <ListItem
            button
            onClick={() => {
              handleClickOpenDialog(
                'importHistoricalData',
                'https://www.youtube.com/embed/Wzhdq8rWM7c?hd=1',
                'https://www.reinvestwealth.com/post/import-historical-bank-transactions-reinveswealth-accounting-software'
              );
              setDoneSteps(prev => ({ ...prev, importHistoricalData: true }));
            }}
          >
            <ListItemText primary={'2. Import Historical Data'} />
            {doneSteps.importHistoricalData && (
              <DoneIcon style={{ marginLeft: 10 }} />
            )}
          </ListItem>
          <Divider />
          <ListItem
            button
            onClick={() => {
              handleClickOpenDialog(
                'installApp',
                'https://www.youtube.com/embed/8-3Xyz7Gs-w?hd=1',
                'https://www.reinvestwealth.com/post/install-reinvestwealth-ai-accounting-app'
              );
              setDoneSteps(prev => ({ ...prev, installApp: true }));
            }}
          >
            <ListItemText primary={'3. Install App'} />
            {doneSteps.installApp && <DoneIcon style={{ marginLeft: 10 }} />}
          </ListItem>
          <Divider />
          <ListItem
            button
            onClick={() => {
              handleClickOpenDialog(
                'uploadReceipt',
                'https://www.youtube.com/embed/4zjGl4oq0N8?hd=1',
                'https://www.reinvestwealth.com/post/uploading-receipts-reinvestwealth-ai-accounting-software'
              );
              setDoneSteps(prev => ({ ...prev, uploadReceipt: true }));
            }}
          >
            <ListItemText primary={'4. Upload Receipt'} />
            {doneSteps.uploadReceipt && <DoneIcon style={{ marginLeft: 10 }} />}
          </ListItem>
          <Divider />
          <ListItem
            button
            onClick={() => {
              handleClickOpenDialog(
                'sendInvoiceWithStripe',
                'https://www.youtube.com/embed/YwnNmTldj-8?hd=1',
                'https://www.reinvestwealth.com/post/sending-invoices-reinvestwealth-stripe'
              );
              setDoneSteps(prev => ({ ...prev, sendInvoiceWithStripe: true }));
            }}
          >
            <ListItemText primary={'5. Send Invoice With Stripe'} />
            {doneSteps.sendInvoiceWithStripe && (
              <DoneIcon style={{ marginLeft: 10 }} />
            )}
          </ListItem>
          <Divider />
          <ListItem
            button
            onClick={() => {
              const videoUrl =
                businessCurrency === 'CAD'
                  ? 'https://www.youtube.com/embed/SdfqkXlVrg8?hd=1'
                  : 'https://www.youtube.com/embed/tCTOVG53nm8?hd=1';
              const onboardingText =
                businessCurrency === 'CAD'
                  ? 'https://www.reinvestwealth.com/post/review-transactions-reinvestwealth-ai-accounting-software-canada'
                  : 'https://www.reinvestwealth.com/post/review-transactions-reinvestwealth-ai-accounting-software-usa';
              handleClickOpenDialog(
                'reviewTransactions',
                videoUrl,
                onboardingText
              );

              setDoneSteps(prev => ({ ...prev, reviewTransactions: true }));
            }}
          >
            <ListItemText primary={'6. Review Transactions'} />
            {doneSteps.reviewTransactions && (
              <DoneIcon style={{ marginLeft: 10 }} />
            )}
          </ListItem>
          <Divider />
          <ListItem
            button
            onClick={() => {
              handleClickOpenDialog(
                'viewFinancialReports',
                'https://www.youtube.com/embed/19eGHDyytUg?hd=1',
                'https://www.reinvestwealth.com/post/view-financial-reports-reinvestwealth-ai-accounting-bookkeeping-software'
              );
              setDoneSteps(prev => ({ ...prev, viewFinancialReports: true }));
            }}
          >
            <ListItemText primary={'7. View Financial Reports'} />
            {doneSteps.viewFinancialReports && (
              <DoneIcon style={{ marginLeft: 10 }} />
            )}
          </ListItem>
          <Divider />
          {/* <ListItem
            button
            onClick={() => {
              handleClickOpenDialog(
                'salesTaxFiling',
                'https://www.youtube.com/embed/BtSS9-Vrtm8?hd=1'
              );
              setDoneSteps(prev => ({ ...prev, salesTaxFiling: true }));
            }}
          >
            <ListItemText primary={'8. Sales Tax Filing'} />
            {doneSteps.salesTaxFiling && (
              <DoneIcon style={{ marginLeft: 10 }} />
            )}
          </ListItem> */}
          <Divider />
        </List>
      </Drawer>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
      >
        <Card className={classes.card}>
          <CardMedia
            component="iframe"
            className={classes.media}
            src={dialogVideo}
            allow="accelerometer, autoplay; encrypted-media, gyroscope"
          />
          <CardActions>
            {/* <Button size="small" color="primary">
              Share
            </Button> */}
            <Button
              variant="contained"
              size="small"
              color="secondary"
              onClick={() => window.open(dialogText, '_blank')}
            >
              Click Here to Learn More
            </Button>
          </CardActions>
        </Card>
      </Dialog>
    </>
  );
};

export default OnboardingGuide;
